import { SimpleLangSelect } from 'components/resort/HeaderLangSelect/HeaderLangSelect';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import Link from 'next/link';
import { SearchPopup } from 'components/home/SearchPopup';
import { useRouter } from 'next/router';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Routes } from 'routes';
import { ReactComponent as SvgLogoSnow } from 'public/assets/white-logo.svg';
import { ReactComponent as SvgLogoSki } from 'public/assets/white-logo_ski.svg';
import { ReactComponent as SvgSearch } from 'public/assets/icons/search.svg';
import { ReactComponent as SvgBurger } from 'public/assets/icons/burger.svg';

import { REVIEW_ANCHOR } from 'page-components/resort/constants';

import classNames from 'classnames';
import { useAnalytics } from 'util/analytics';
import { getPortalOptions } from 'util/getPortalConfig';
import { getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';
import { isMainLocale } from 'util/i18nFlags';

import { IS_SSR } from 'ots-constants';
import { setFocusToSearchInput } from 'util/setFocusToInputByQuery';
import { useNoHeader } from 'util/useNoHeader';
import { useDefaultScrollToTop } from 'util/useScrollToTop';

import ratingSearchBlockStyles from 'src/components/common/RateResortSearch/styles.module.scss';
import { SmartBannerInit } from 'components/common/SmartBanner';
import { useSettingsPrefetch } from 'settings/SettingsContext';
import styles from './styles.module.scss';

export enum HeaderNavCurLink {
  SNOW_REPORTS = 'snow_reports',
  TRIP_PLANNING = 'trip_planning',
  MAGAZINE = 'magazine',
  MAIN = 'index',
}

export const HeaderNav = ({
  current,
}: {
  current: HeaderNavCurLink
}) => {
  const prefetch = useSettingsPrefetch();
  const { t } = useOTSTranslation('common');
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const a = useAnalytics();

  const divRef = useRef<HTMLDivElement>(null);

  const toggleMenu = useCallback(() => {
    document.body.classList.toggle('search-overlay');
    setMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const toggleSearch = useCallback(() => {
    new Promise<void>((resolve) => {
      setSearchOpen(!isSearchOpen);
      if (isSearchOpen) {
        a.searchBox();
      }
      resolve();
    }).then(() => {
      setFocusToSearchInput();
    });
  }, [isSearchOpen]);

  useEffect(() => () => document.body.classList.remove('search-overlay'), []);

  const handleFocusSearch = useCallback((e) => e.target.blur(), []);

  const hasMagazine = getPortalOptions().hasNews;

  const showUSLogo = [ELocale.EN_US, ELocale.EN_GB, ELocale.CS_CZ, ELocale.SK_SK].includes(getLocale() as ELocale);
  const logoRef = useRef<HTMLAnchorElement>(null);
  const router = useRouter();
  const hash = router?.asPath.split('#')[1];

  useDefaultScrollToTop(0, 0);

  const refocusToRatingBlock = useCallback((e) => {
    if (e?.relatedTarget?.id?.indexOf('onetrust') >= 0
      && !IS_SSR
      && hash === REVIEW_ANCHOR) {
      const scrollableBlock = document.getElementsByClassName(ratingSearchBlockStyles.rateResortSearchContainer)[0]
        || document.getElementById(REVIEW_ANCHOR);

      scrollableBlock?.scrollIntoView();
    }
  }, [hash]);

  useEffect(() => {
    logoRef?.current?.addEventListener('focus', refocusToRatingBlock);
    return () => logoRef?.current?.removeEventListener('focus', refocusToRatingBlock);
  }, []);

  const closeSearch = useCallback(() => setSearchOpen(false), [setSearchOpen]);

  useEffect(() => {
    router.events.on('routeChangeComplete', closeSearch);

    return () => {
      router.events.off('routeChangeComplete', closeSearch);
    };
  }, [router.events]);

  const { noHeader } = useNoHeader();
  if (noHeader) {
    return null;
  }

  return (
    <>
      {
        isSearchOpen && <SearchPopup closePopup={toggleSearch} />
      }
      <SmartBannerInit />
      <nav ref={divRef} className={classNames(styles.box, { [styles.searchOpen]: isSearchOpen })}>
        <div className="container-xl p-0">
          <div>
            <div className={styles.logo}>
              <Link scroll={false} href={Routes.root} prefetch={prefetch}>
                <a href={Routes.root} ref={logoRef} tabIndex={-1}>
                  {/* eslint-disable-next-line no-constant-condition */}
                  {showUSLogo ? <SvgLogoSnow /> : <SvgLogoSki />}
                </a>
              </Link>
            </div>
            <div className={styles.desktopMenu}>
              <Link scroll={false} href={Routes.snowReports()} prefetch={prefetch}>
                <a
                  href={Routes.snowReports()}
                  className={classNames({
                    [styles.current]: current === HeaderNavCurLink.SNOW_REPORTS,
                  })}
                >
                  {t('nav.global.snowReports')}
                </a>
              </Link>
              {isMainLocale() && (
                <Link scroll={false} href={Routes.tripPlanning()} prefetch={prefetch}>
                  <a
                    href={Routes.tripPlanning()}
                    className={classNames({
                      [styles.current]: current === HeaderNavCurLink.TRIP_PLANNING,
                    })}
                  >
                    {t('nav.global.planning')}
                  </a>
                </Link>
              )}
              {hasMagazine
                && (
                  <a
                    href={Routes.magazine}
                    className={classNames({
                      [styles.current]: current === HeaderNavCurLink.MAGAZINE,
                    })}
                  >
                    {t('nav.global.magazine')}
                  </a>
                )}
            </div>
          </div>
          <div>
            <div className={classNames(styles.search, { [styles.searchOpen]: isSearchOpen })}>
              <form>
                <input
                  className={styles.searchInput}
                  type="search"
                  name="search"
                  placeholder={t('nav.global.searchBox')}
                  onFocus={handleFocusSearch}
                  onClick={toggleSearch}
                />
                <button
                  className={styles.searchButton}
                  type="button"
                  title={t('nav.global.searchBox')}
                  onClick={toggleSearch}
                >
                  <SvgSearch />
                </button>
              </form>
            </div>
            <div className={styles.langPicker}>
              <SimpleLangSelect />
            </div>
            <div tabIndex={-1} role="button" className={styles.searchToggle} onClick={toggleSearch}>
              <SvgSearch />
            </div>
            <div tabIndex={-1} role="button" className={styles.burger} onClick={toggleMenu}>
              <SvgBurger />
            </div>
          </div>
        </div>
        <div className={classNames(styles.mobileMenu, { [styles.open]: isMenuOpen })}>
          <div className={styles.head}>
            <div className="container">
              <div tabIndex={-1} role="button" className={styles.burger} onClick={toggleMenu}>
                <SvgBurger />
              </div>
            </div>
          </div>
          <div className={styles.links}>
            <Link scroll={false} href={Routes.snowReports()} prefetch={prefetch}>
              <a
                href={Routes.snowReports()}
                className={classNames({
                  [styles.current]: current === HeaderNavCurLink.SNOW_REPORTS,
                })}
              >
                {t('nav.global.snowReports')}
              </a>
            </Link>
            {isMainLocale() && (
              <Link scroll={false} href={Routes.tripPlanning()} prefetch={prefetch}>
                <a
                  href={Routes.tripPlanning()}
                  className={classNames({
                    [styles.current]: current === HeaderNavCurLink.TRIP_PLANNING,
                  })}
                >
                  {t('nav.global.planning')}
                </a>
              </Link>
            )}
            {hasMagazine
              && (
                <a
                  href={Routes.magazine}
                  className={classNames({
                    [styles.current]: current === HeaderNavCurLink.MAGAZINE,
                  })}
                >
                  {t('nav.global.magazine')}
                </a>
              )}
          </div>
        </div>
      </nav>
    </>
  );
};
