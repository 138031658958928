import classNames from 'classnames';
import { ISearchResultItem, SearchCategory } from 'api/home/types';
import NoResutls from 'components/home/SearchPopup/NoResults';
import { Routes } from 'routes';
import SearchLink from 'components/home/SearchPopup/SearchLink';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { I18nKey } from 'i18n/types';

import styles from './styles.module.scss';

interface ISearchColumnProps {
  list: Array<ISearchResultItem>
  label: string
  query: string
}

const SearchResultsColumn: React.FC<ISearchColumnProps> = ({
  list, query,
}) => {
  const { t } = useOTSTranslation('common');

  return (
    <div className={classNames(styles.category, { [styles.categoryEmpty]: !list.length })}>
      <div className={styles.list}>
        {!list.length && (<NoResutls />)}
        {list.map((item) => {
          const isResort = item.categoryUuid === SearchCategory.resorts;
          const isRegion = item.categoryUuid === SearchCategory.regions;
          const isOther = item.categoryUuid === SearchCategory.other;
          const shouldShowCategory = item.categoryUuid !== SearchCategory.other;
          const category = item.categoryUuid.slice(0, -1);
          let href = '';

          if (isResort) {
            href = Routes.resortSnowReport(item.resort);
          }
          if (isRegion) {
            href = Routes.regionSnowReport(item.region);
          }
          if (isOther) {
            href = item.href;
          }

          return (
            <SearchLink
              key={item.uuid}
              query={query}
              className={styles.item}
              href={href}
            >
              <span>{item.title}</span>
              {shouldShowCategory && <span className={styles.tag}>{t(`searchResults.${category}` as I18nKey['common'])}</span>}
            </SearchLink>
          );
        })}
      </div>
    </div>
  );
};

export default SearchResultsColumn;
