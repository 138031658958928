import { getLang } from 'i18n/locale';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import React, { useEffect } from 'react';
import { Diagnostics } from 'util/diagnostics';
import { useNoHeader } from 'util/useNoHeader';
import { SizeMonitor } from 'components/size/SizeMonitor';

const SmartBannerInitRaw = () => {
  const { t } = useOTSTranslation('common');
  const { noHeaderImmediate } = useNoHeader();

  useEffect(() => {
    const SB = (global as any).SmartBanner;
    if (SB && !noHeaderImmediate) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const banner = new (SB)({
        daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        appStoreLanguage: getLang(), // language code for the App Store (defaults to user's browser language)
        title: t('smartBanner.otsOnAndroid'),
        author: '',
        button: t('smartBanner.ctaOpen'),
        store: {
          android: t('smartBanner.appTitle'),
        },
        price: {
          android: '',
        },
        icon: 'https://images.onthesnow.com/images/apple-touch-icon.png',
      });
    } else {
      Diagnostics.debug('SmartBanner not loaded');
    }
  }, []);

  return <SizeMonitor id="smartbanner" waitInteraction><div id="smart-banner-placeholder" /></SizeMonitor>;
};

export const SmartBannerInit = React.memo(SmartBannerInitRaw);

export const SmartBannerHead = () => (
  <>
    <link
      rel="stylesheet"
      href="/smartbanner/banner.css"
    />
    <script defer src="/smartbanner/banner.js" />
  </>
);
