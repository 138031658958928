import { NextLink } from 'components/blocks/NextLink';
import { AnchorHTMLAttributes, useCallback } from 'react';
import { useAnalytics } from 'util/analytics';

const SearchLink = (
  {
    query, href, children, ...props
  }: AnchorHTMLAttributes<HTMLAnchorElement> & { href: string, query: string },
) => {
  const a = useAnalytics();
  const onClick = useCallback(() => {
    a.searchClick({ url: href, query });
  }, [a, href, query]);

  return <NextLink onClick={onClick} href={href} {...props}>{children}</NextLink>;
};

export default SearchLink;
